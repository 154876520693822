<script setup lang="ts">
import { Bars3Icon } from '@heroicons/vue/24/outline';

const auth = useAuth();
const config = useRuntimeConfig();
const instance = getCurrentInstance();

const navigation = [
  {
    name: 'ホーム',
    attrs: {
      to: { name: 'index' },
    },
  },
  {
    name: '購入履歴',
    attrs: {
      to: { name: 'purchase-history' },
    },
  },
  {
    name: '利用者情報変更',
    attrs: {
      to: { name: 'accounts-profile' },
    },
  },
  {
    name: 'FAQ',
    attrs: {
      href: config.public.FAQ_SITE_URL,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  },
];

const uid = ref(instance?.uid);
const mobileMenuOpen = ref(false);
</script>

<template>
  <header class="bg-white">
    <nav
      class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
      aria-label="Global"
    >
      <NuxtLink :to="{ name: 'index' }" class="-m-1.5 p-1.5">
        <span class="sr-only">L-BOX</span>
        <img class="h-10 w-auto" src="~/assets/logo.png" alt="" />
      </NuxtLink>
      <div class="flex lg:hidden">
        <button
          type="button"
          class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          @click="mobileMenuOpen = true"
        >
          <span class="sr-only">Open main menu</span>
          <Bars3Icon class="size-6" aria-hidden="true" />
        </button>
      </div>
      <div class="hidden lg:flex lg:gap-x-12">
        <NuxtLink
          v-for="item in navigation"
          :key="`${uid}-${item.name}`"
          v-bind="item.attrs"
          class="text-sm font-semibold leading-6 text-gray-900"
          >{{ item.name }}</NuxtLink
        >
        <button
          type="button"
          class="text-sm font-semibold leading-6 text-gray-900"
          @click="auth.logout()"
        >
          ログアウト
        </button>
      </div>
    </nav>

    <SiteHeaderMobileModal
      :open="mobileMenuOpen"
      :navigation
      @close="mobileMenuOpen = false"
    />
  </header>
</template>
