<template>
  <Dialog
    as="div"
    class="lg:hidden"
    :open="open"
    :initial-focus="initialFocus"
    @close="$emit('close')"
  >
    <div class="fixed inset-0 z-10" />
    <DialogPanel
      class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white p-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
    >
      <div class="flex items-center justify-between">
        <NuxtLink :to="{ name: 'index' }" class="-m-1.5 p-1.5">
          <span class="sr-only">L-BOX</span>
          <img class="h-10 w-auto" src="~/assets/logo.png" alt="" />
        </NuxtLink>
        <button
          ref="initialFocus"
          type="button"
          class="-m-2.5 rounded-md p-2.5 text-gray-700"
          @click="$emit('close')"
        >
          <span class="sr-only">Close menu</span>
          <XMarkIcon class="size-6" aria-hidden="true" />
        </button>
      </div>
      <div class="mt-6 flow-root">
        <div class="-my-6 divide-y divide-gray-500/10">
          <div class="space-y-2 py-6">
            <NuxtLink
              v-for="item in navigation"
              :key="`${uid}-${item.name}`"
              v-bind="item.attrs"
              class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
              @click="$emit('close')"
              >{{ item.name }}</NuxtLink
            >
            <button
              type="button"
              class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
              @click="
                $emit('close');
                auth.logout();
              "
            >
              ログアウト
            </button>
          </div>
        </div>
      </div>
    </DialogPanel>
  </Dialog>
</template>

<script lang="ts" setup>
import { Dialog, DialogPanel } from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';

import type { RouteLocationRaw } from '#vue-router';

const auth = useAuth();
const instance = getCurrentInstance();

// Define the props and emits
interface Props {
  navigation: {
    name: string;
    attrs: {
      to?: RouteLocationRaw;
      href?: string;
      target?: string;
      rel?: string;
    };
  }[];
  open: boolean;
}

withDefaults(defineProps<Props>(), {});

interface Emits {
  (e: 'close'): void;
}
defineEmits<Emits>();

// refs
const uid = ref(instance?.uid);
const initialFocus = ref<HTMLElement | null>(null);
</script>
